body {
  --colors-layout-highlight: #f67503;

  --colors-report-save-background: #f67501;

  --transitions-buttons: color 0.2s, border 0.2s;

  --attitude-positive: #008000;
  --attitude-negative: #ff0000;

  --colors-typography-success: #02c000;
  --colors-typography-errors: #ed171d;
  --colors-button-errors: #df1017;

  --octothorp: #7a8283;

  --colors-buttons-controls-background: #ffffff;
  --colors-buttons-controls-hover: #a7a7a7;
  --colors-buttons-controls-text: #00678d;

  --indent-step: 8;
}

body,
body[data-theme='light'] {
  color: #000000;

  --colors-borders: #d7dddf;
  --colors-borders-lists: rgba(1, 103, 141, 0.1);

  --colors-layout-background: #ffffff;
  --colors-layout-content: #ffffff;
  --colors-layout-toolbar: rgba(1, 103, 141, 0.1);

  --colors-layout-notegrouplist: #f7f3f0;
  --colors-layout-notegrouplist-active: #eae5e1;

  --colors-card-background: #f7f7f7;
  --colors-card-borders: rgb(1 103 141 / 0.1);
  --colors-card-borders-hover: rgb(1 103 141 / 0.2);

  --colors-hint-background: #f7f4f1;
  --colors-hint-title: #003a4f;
  --colors-hint-icon: #003a4f;
  --colors-hint-body: #4d4d4d;
  --colors-hint-footer: #00678d;
  --colors-hint-close-button: #00678d;

  --colors-hr: rgb(1 103 141 / 0.1);
  --colors-placeholders-fill: #dddddd;
  --colors-placeholders-pulse: #eeeeee;

  --colors-sidenav-background: #f7f7f7;
  --colors-sidenav-background-active: #dfdfe0;
  --colors-sidenav-text: #000000;

  --colors-action-bar-background: #024058;

  --colors-horizontal-bar-background: #a8dce3;

  --colors-buttons-primary-background: #00678d;
  --colors-buttons-danger-background: #d9534f;
  --colors-buttons-primary-hover: #017dab;
  --colors-buttons-primary-text: #ffffff;

  --colors-buttons-callout-background: #01678d;
  --colors-buttons-callout-hover: #0f779c;

  --colors-buttons-alt-text: #024058;
  --colors-buttons-alt-background: #ffffff;
  --colors-buttons-alt-hover: #f9f9f9;

  --colors-button-reset-background: rgba(223, 223, 224, 0.5);
  --colors-button-reset-text: #00384e;
  --colors-button-reset-hover: rgba(223, 223, 224, 0.3);

  --colors-buttons-document-link: #1d566e;
  --colors-buttons-dropdown-text: #374151;
  --colors-buttons-dropdown-active: rgb(229 231 235);

  --colors-tags-text: #ffffff;
  --colors-tags-background: #46b7c3;

  --color-tags-button-text: #003b55;

  --colors-buttons-menu-border: #cccccc;
  --colors-buttons-menu-text: #024058;
  --colors-buttons-menu-open-background: #ffffff;
  --colors-buttons-menu-open-text: #024058;

  --colors-metadata-background: #dfdfe0;

  --colors-dropdowns-open-background: #ffffff;
  --colors-dropdowns-open-text: #024058;

  --colors-typography-primary: #3c4c4f;
  --colors-typography-dark: #343f42;
  --colors-typography-dark-gray: #656767;
  --colors-typography-link: #00384e;
  --colors-typography-labels: #202020;
  --colors-typography-labels-alt: #666666;
  --colors-typography-labels-sub: #000000;
  --colors-typography-meta: #677073;
  --colors-typography-anchor: #268bb3;
  --colors-typography-header: #000000;
  --colors-typography-card-primary: #2c393d;

  --colors-card-background-ai: #fbfbfb;

  --colors-typography-titles: #00384d;
  --colors-typography-clips: #575757;
  --colors-typography-notes: #000000;
  --colors-typography-zoom-link: #2db0c0;
  --colors-typography-ai: #434343;
  --colors-typography-menu: rgba(0, 56, 78, 0.8);

  --colors-keyword-background: #f5f5f5;

  --colors-inputs-checkbox-background: #003145;
  --colors-inputs-radio-background: #003145;
  --colors-inputs-text-color: #00678d;
  --colors-inputs-text-placeholder: #999999;
  --colors-inputs-text-border: rgba(1, 103, 141, 0.1);
  --colors-inputs-text-background: rgba(1, 103, 141, 0.03);
  --colors-inputs-text-background-focus: #003145;
  --colors-inputs-placeholder: rgba(0, 103, 141, 0.8);

  --colors-inputs-search-background: rgba(248, 248, 248, 0.9);
  --colors-inputs-search-border: rgba(1, 103, 141, 0.1);

  --colors-table-odd: #eeeeee;
  --colors-table-even: #a79f9f;

  --colors-icons: #00678d;

  --colors-tags-border: #00384e;
  --colors-tags-color: #202020;

  --tooltip-shadow: 1px 2px 4px 0 rgba(2, 64, 88, 0.23);

  --dropshadow: 0 6px 6px 0 rgba(0, 0, 0, 0.18);

  --colors--modal-background: #ffffff;
  --colors-custom-modal-background: #f7f4f1;
  --colors-custom-button-background: #f5f5f5;
  --colors-reportbuilder-background: #eeeeee;
  --colors-paragraph: #677073;
  --colors-secondary: #677073;

  --drawer-body-background-top: linear-gradient(
    white 30%,
    rgba(255, 255, 255, 0)
  );
  --drawer-body-background-bottom: linear-gradient(
    rgba(255, 255, 255, 0),
    white 70%
  );
  --drawer-body-radial-top: radial-gradient(
    farthest-side at 50% 0,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
  --drawer-body-radial-bottom: radial-gradient(
    farthest-side at 50% 100%,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );

  --colors-popover: rgba(0, 0, 0, 0.75);
}

body[data-theme='dark'] {
  color: #ffffff;

  --colors-borders: #282220;
  --colors-borders-lists: rgba(254, 152, 114, 0.1);

  --colors-layout-background: #000000;
  --colors-layout-content: #060606;

  --colors-layout-toolbar: rgba(254, 152, 114, 0.1);

  --colors-layout-notegrouplist: #080c0f;
  --colors-layout-notegrouplist-active: #151a1e;

  --colors-card-background: #292929;
  --colors-card-borders: #fe9872;
  --colors-card-borders-hover: #fead8e;

  --colors-hint-background: #404c50;
  --colors-hint-title: #dddddd;
  --colors-hint-icon: #2bb1bf;
  --colors-hint-body: #dddddd;
  --colors-hint-footer: #dddddd;
  --colors-hint-close-button: #dddddd;

  --colors-hr: #414040;
  --colors-placeholders-fill: #222222;
  --colors-placeholders-pulse: #111111;

  --colors-sidenav-background: #0d0d0b;
  --colors-sidenav-background-active: #20201f;
  --colors-sidenav-text: #abbcc4;

  --colors-action-bar-background: #021223;

  --colors-horizontal-bar-background: #414040;

  --colors-buttons-primary-background: #abbcc4;
  --colors-buttons-danger-background: #d9534f;
  --colors-buttons-primary-hover: #9b9b9b;
  --colors-buttons-primary-text: #000000;

  --colors-buttons-callout-background: #fe9872;
  --colors-buttons-callout-hover: #ffad90;

  --colors-buttons-alt-text: #abbcc4;
  --colors-buttons-alt-background: transparent;
  --colors-buttons-alt-hover: #060606;

  --colors-button-reset-background: rgba(32, 32, 31, 0.5);
  --colors-button-reset-text: #e5b79a;
  --colors-button-reset-hover: rgba(32, 32, 31, 0.3);

  --colors-buttons-document-link: #abbcc4;
  --colors-buttons-dropdown-text: #dad6cc;
  --colors-buttons-dropdown-active: rgba(1, 103, 141, 0.2);

  --colors-tags-text: #000000;
  --colors-tags-background: #b9483c;

  --color-tags-button-text: #ffffff;

  --colors-buttons-menu-border: #333333;
  --colors-buttons-menu-text: #abbcc4;
  --colors-buttons-menu-open-background: #20201f;
  --colors-buttons-menu-open-text: #abbcc4;

  --colors-dropdowns-open-background: #20201f;
  --colors-dropdowns-open-text: #abbcc4;

  --colors-typography-primary: #c3b3b0;
  --colors-typography-dark: #cbc0bd;
  --colors-typography-link: #e5b79a;
  --colors-typography-labels: #dfdfdf;
  --colors-typography-labels-alt: #999999;
  --colors-typography-labels-sub: #ffffff;
  --colors-typography-meta: #677073;
  --colors-typography-anchor: #dfdfdf;
  --colors-typography-header: #ffffff;
  --colors-typography-titles: #d3c6c2;
  --colors-typography-clips: #a8a8a8;
  --colors-typography-notes: #ffffff;
  --colors-typography-zoom-link: #2db0c0;
  --colors-typography-ai: #bcbcbc;
  --colors-typography-menu: rgba(255, 199, 177, 0.8);

  --colors-card-background-ai: #040404;

  --colors-keyword-background: #282220;

  --colors-inputs-checkbox-background: #abbcc4;
  --colors-inputs-radio-background: #abbcc4;
  --colors-inputs-text-color: #abbcc4;
  --colors-inputs-text-placeholder: #999999;
  --colors-inputs-text-border: #aaaaaa;
  --colors-inputs-text-background: #000000;
  --colors-inputs-text-background-focus: #abbcc4;
  --colors-inputs-placeholder: rgb(0, 103, 141, 0.75);

  --colors-inputs-search-background: rgba(7, 7, 7, 0.9);
  --colors-inputs-search-border: rgba(254, 152, 114, 0.1);

  --colors-table-odd: #111111;
  --colors-table-even: #bebfbf;

  --colors-icons: #ffffff;

  --colors-tags-border: #cccccc;
  --colors-tags-color: #dfdfdf;

  --tooltip-shadow: 1px 2px 4px 0 rgba(82, 82, 82, 0.23);

  --dropshadow: 0px 4px 24px rgba(255, 255, 255, 0.12);

  --colors--modal-background: #292929;
  --colors-custom-modal-background: #0d0d0b;

  --colors-reportbuilder-background: #3b3b3b;
  --colors-custom-button-background: rgba(110, 110, 110, 0.5);
  --colors-secondary: #677073;
  --colors-secondary-button: #677073;

  --mode-light: yellow;

  --drawer-body-background-top: linear-gradient(black 30%, rgba(0, 0, 0, 0));
  --drawer-body-background-bottom: linear-gradient(rgba(0, 0, 0, 0), black 70%);
  --drawer-body-radial-top: radial-gradient(
    farthest-side at 50% 0,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  --drawer-body-radial-bottom: radial-gradient(
    farthest-side at 50% 100%,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );

  --colors-popover: rgba(255, 255, 255, 0.75);
}
